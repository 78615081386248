import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import BounceLoader from 'react-spinners/BounceLoader';
import { Col, Container, Row } from 'reactstrap';
import { BranchCreation } from '../../open-api';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import { adminRoleExists, countryCodeOptionList, countryOptionList, ISelectOptionItem, statusOptionList } from '../../common/utils';
import CustomSelect from '../../components/custom-select/custom-select';
import { getRequestConfig } from '../../common/utils';
import { useHistory } from 'react-router-dom';
import mainAxios from '../../config/axios-config';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const CreateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #005FAA;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const emptyCreateObject:BranchCreation = {
  name: '',
  code: '',
  mobile_contact: {
    country_calling_code: '+65',
    phone_number: '',
  },
  country: '',
  address: '',
  status: 'ACTIVE',
}
function CreateBranch() {
  const history = useHistory();

  const [newCreateObject, setNewCreateObject] = useState<BranchCreation>(emptyCreateObject);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const userStore = useUserStore(); 

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!adminRoleExists(userStore.user.roles)) {
        history.push("/not-found");
      }
    }

  }, [userStore.accessToken, userStore.user, history])


  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      [field]: { $set: value }
    })
    setNewCreateObject(tempOrder);
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewCreateObject(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newCreateObject.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newCreateObject.mobile_contact.phone_number) {
      if (!mobileError) {
        setMobileError(true)
      }
      haveError = true;
    } else {
      if (mobileError) {
        setMobileError(false)
      }
    }

    if (!newCreateObject.code) {
      if (!codeError) {
        setCodeError(true)
      }
      haveError = true;
    } else {
      if (codeError) {
        setCodeError(false)
      }
    }

    if (!newCreateObject.country) {
      
      if (!countryError) {
        setCountryError(true)
      }
      haveError = true;
    } else {
      if (countryError) {
        setCountryError(false)
      }
    }

    if (!newCreateObject.address) {
      if (!addressError) {
        setAddressError(true)
      }
      haveError = true;
    } else {
      if (addressError) {
        setAddressError(false)
      }
    }

    return haveError;
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      console.log(newCreateObject.country);
      setLoading(false);
      return;
    }

    let branchApi: amberSdk.BranchApi = new amberSdk.BranchApi(undefined, undefined, mainAxios)

    branchApi.branchCreatePOST({
      name: newCreateObject.name,
      mobile_contact: {
        country_calling_code: newCreateObject.mobile_contact.country_calling_code,
        phone_number: newCreateObject.mobile_contact.phone_number,
      },
      code: newCreateObject.code,
      status: newCreateObject.status,
      country: newCreateObject.country,
      address: newCreateObject.address,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully created', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewCreateObject(emptyCreateObject);
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        } else if (e.response.data.error === 'invalid_code') {
          setCodeError(true);
        }
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1 className="page-header">Create Amber Branch</h1>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "32px" }}>
          <Col md={6}>
            <span className="p1">Create a new amber branch</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>Branch Name*</Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${
                nameError ? "is-invalid" : ""
              }`}
              placeholder="Branch Name"
              id="branchName"
              onChange={(e) => handleInputChange("name", e.target.value)}
              value={newCreateObject.name}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>Branch Code*</Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${
                codeError ? "is-invalid" : ""
              }`}
              placeholder="Branch Code"
              id="branchName"
              onChange={(e) => handleInputChange("code", e.target.value)}
              value={newCreateObject.code}
            />
            <div className="invalid-feedback">Invalid branch code</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>Phone Number*</Col>
          <Col md={4} className='mobile_check phone-field' style={{ display: "flex" }}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) =>
                handleMobileChange("country_calling_code", e.value)
              }
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(
                (e) =>
                  e.value ===
                  newCreateObject.mobile_contact.country_calling_code
              )}
            />
            <input
              type="text"
              className={`form-control fs-13px ${
                mobileError ? "is-invalid" : ""
              }`}
              placeholder="Phone Number"
              id="mobileNumber"
              onChange={(e) =>
                handleMobileChange("phone_number", e.target.value)
              }
              value={newCreateObject.mobile_contact.phone_number}
            />
            <div className="invalid-feedback error_mobile">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>Country*</Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) =>
                handleInputChange("country", e.value)
              }
              className={`${
                countryError ? "is-invalid" : ""
              }`}
              options={countryOptionList}
              placeholder="Country"
              value={countryOptionList.find(
                (e) => e.value === newCreateObject.country
              )}
              isError={countryError}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>Address*</Col>
          <Col md={4}>
            <textarea
              rows={5}
              className={`form-control fs-13px ${
                addressError ? "is-invalid" : ""
              }`}
              placeholder="Address"
              id="address"
              onChange={(e) => handleInputChange("address", e.target.value)}
              value={newCreateObject.address}
              style={{ minHeight: "142px" }}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>Branch Status*</Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) =>
                handleInputChange("status", e.value)
              }
              
              options={statusOptionList}
              isSearchable
              placeholder="Status"
              value={statusOptionList.find(
                (e) => e.value === newCreateObject.status
              )}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}></Col>
          <Col md={2}>
            <CancelButton type="button" onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <CreateButton type="submit" onClick={handleSubmit}>
              + Create branch
            </CreateButton>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateBranch;