import moment from "moment";

interface OptionItem {
  name?: string,
  id?: string,
}

export function FormatNameIdToSelectOption(item: OptionItem) {
  if (item === undefined || item === null) {
    return null;
  }
  return {
    value: item.id,
    label: item.name
  }
}

export function FormatNameIdToSelectMultiOption(valueArray?: OptionItem[]) {
  return valueArray?.map((e) => ({
    value: e.id,
    label: e.name
  }))
}

export function IsValidEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function containsLetter(password?: string) {
  if (!password) {
    return false;
  }
  if (password.length < 8) {
    return false;
  }
  if (password.search(/[a-z]/i) < 0) {
    return false;
  }
  return true;
};

export function containsNumber(password?: string) {
  if (!password) {
    return false;
  }
  if (password.length < 8) {
    return false;
  }
  if (password.search(/[0-9]/) < 0) {
    return false;
  }
  return true;
};

export function getFromDateUndefinedOrISO(dateString: string | undefined) {
  if (dateString === undefined ||
    dateString === ''
  ) {
    return undefined;
  }

  return moment(dateString).toISOString();
}

export function getToDateUndefinedOrISO(dateString: string | undefined) {
  if (dateString === undefined ||
    dateString === ''
  ) {
    return undefined;
  }

  return moment(dateString).add(1, 'd').toISOString();
}

export function getEncodeOrUndefined(stringToEncode: string | undefined) {
  if (stringToEncode === undefined ||
    stringToEncode === ''
  ) {
    return undefined;
  }
  return encodeURI(stringToEncode);
}

export interface ISelectOptionItem {
  value: string;
  label: string;
}

export function mapSelectedToValueList(selectedList: Array<ISelectOptionItem>) {
  return selectedList.map((e) => e.value);
}

export const accountStatusOptionList = [{
  value: 'ACTIVE',
  label: 'Active',
}, {
  value: 'PENDING',
  label: 'Pending',
}, {
  value: 'INACTIVE',
  label: 'Inactive',
}
]

export const statusOptionList = [{
  value: 'ACTIVE',
  label: 'Active',
}, {
  value: 'INACTIVE',
  label: 'Inactive',
}
]

export const countryFilterOption = [
  { value: 'SINGAPORE', label: 'Singapore' },
  { value: 'MALAYSIA', label: 'Malaysia' },
];

export function getRequestConfig(accessToken: string) {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  }
}

export function getSortDirection(selectedDirection: string | undefined) {
  switch (selectedDirection) {
    case ('asc'):
      return 'ASC' as const;
    case ('desc'):
      return 'DESC' as const;
    default:
      return undefined;
  }
}

export const countryCodeOptionList: Array<ISelectOptionItem> = [{
  value: '+65',
  label: '+65',
}, {
  value: '+60',
  label: '+60',
  }
  // {
  //   value: '+91',
  //   label: '+91',
  // }
]

export const countryOptionList: Array<ISelectOptionItem> = [{
  value: 'Singapore',
  label: 'Singapore',
}, {
  value: 'Malaysia',
  label: 'Malaysia',
},
]

export interface IParamTypes {
  id: string;
}

export const userRoleOptions: Array<ISelectOptionItem> = [
  {
    value: 'ADMIN',
    label: 'Admin'
  },
  {
    value: 'DISPENSER',
    label: 'Dispenser',
  },
  {
    value: 'PHARMACIST',
    label: 'Pharmacist',
  }
]

export function adminRoleExists(roles: Array<string>): boolean {
  if (roles && roles.find(e => e === 'ADMIN')) {
    return true;
  }
  return false;
}

export function dispenserRoleExists(roles: Array<string>) {
  if (roles && roles.find(e => e === 'DISPENSER')) {
    return true;
  }
  return false;
}