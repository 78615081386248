import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useUserStore } from "../../store/userStore";
import SessionTimeoutDialog from "./timeout-dialog";
import * as amberSdk from '../../open-api';
import { getRequestConfig } from "../../common/utils";
import { useHistory } from "react-router";
import LogoutDialog from "./logout-dialog";

const SessionTimeout = () => {
  // Set timeout values
  // const timeout = 1000 * 60 * 0.5;
  const timeout = 1000 * 60 * 10;
  const promptTimeout = 1000 * 60 * 8;
  const userStore = useUserStore();
  const history = useHistory();

  // Modal open state
  const [open, setOpen] = useState(false)
  const [openLoggedOut, setOpenLoggedOut] = useState(false)

  // Time before idle
  const [remaining, setRemaining] = useState(0)

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 15 minutes. Here you can open your prompt. 
    // All events are disabled while the prompt is active. 
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true)
    setRemaining(promptTimeout)
  }

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 60 seconds. Here you can close your prompt and 
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false)
    setRemaining(0)
    setOpenLoggedOut(true)
    userStore.unsetUser();
    localStorage.removeItem('amber_refresh_token');
  }

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()` 
    // is true. Here you will also want to close your modal and perform
    // any active actions. 
    setOpen(false)
    setRemaining(0)

    console.log('onactive refresh session token');

    let refreshToken = localStorage.getItem('amber_refresh_token') || '';

    let authApi: amberSdk.AuthApi = new amberSdk.AuthApi();

    authApi.loginRefreshPOST({
      refresh_token: refreshToken
    }).then((e) => {
      userStore.setAccessToken(e.data.access_token);
      localStorage.setItem('amber_refresh_token', e.data.refresh_token);
    }).catch((error) => {
      if (error.response)
        console.log(error);
      userStore.unsetUser();
      console.log('error in loginRefreshPOST');

      history.push('/login');
    })
  }

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    stopOnIdle: true,
    onPrompt,
    onIdle,
    onActive,
  })

  const handleStillHere = () => {
    setOpen(false)
    activate()
  }

  useEffect(() => {
    
    let refreshToken = localStorage.getItem('amber_refresh_token') || '';

    if(!refreshToken) {
      return;
    }

    let authApi: amberSdk.AuthApi = new amberSdk.AuthApi();

    authApi.loginRefreshPOST({
      refresh_token: refreshToken
    }).then((e) => {
      userStore.setAccessToken(e.data.access_token);
      localStorage.setItem('amber_refresh_token', e.data.refresh_token);

      // pull redirect to login if not authenticated
      let profileApi: amberSdk.ProfileApi = new amberSdk.ProfileApi()
      profileApi.meGET(
        getRequestConfig(e.data.access_token)
      ).then((e) => {
        userStore.setUser(e.data);
      }).catch((error) => {
        if (error.response)
          console.log(error.response);
        userStore.unsetUser();
        history.push('/login');
      });

    }).catch((error) => {
      if (error.response)
        console.log(error);
      userStore.unsetUser();
      history.push('/login');
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <div>
      <SessionTimeoutDialog open={open} countdown={remaining} onContinue={handleStillHere} />
      {openLoggedOut && 
        <LogoutDialog open={openLoggedOut} />
      }
    </div>
  )
}
export default SessionTimeout;