import React, { useState, useEffect, useCallback } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import 'react-datetime/css/react-datetime.css';
import CustomSelect from '../../components/custom-select/custom-select';
import PurchasersTable from './purchaser-table';
import Pagination from '../../components/pagination/pagination';
import { columnsDefinition } from './table-meta';
import { ClinicPurchaserCreationStatusEnum, ClinicPurchasersRetrievalClinicPurchasers, PagingMetadata } from '../../open-api';
import { Link, useHistory } from 'react-router-dom';
import { userStatusOption } from '../../components/account-status/account-status';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import * as amberSdk from '../../open-api';
import { adminRoleExists, getEncodeOrUndefined, getRequestConfig, getSortDirection } from '../../common/utils';
import {
  CreateButton, EntriesDiv, FilterColumn, FilterRow, ListContentWrapper,
  ListHeader, ListHeaderWrapper, ListWrapper, LoaderWrapper, PageFooter, PageHeader,
  SearchDiv, SearchInput, SearchOptionRow
} from '../../common/styles';
import mainAxios from '../../config/axios-config';

function getSortColumn(selectedColumn: string | undefined) {
  switch (selectedColumn) {
    case ('name'):
      return 'NAME' as const;
    case ('mobile_contact'):
      return 'MOBILE' as const;
    case ('clinic_name'):
      return 'CLINIC_NAME' as const;
    case ('date_created'):
      return 'DATE_CREATED' as const;
    case ('status'):
      return 'STATUS' as const;
    default:
      return undefined;
  }
}

function getStatusFilterArray(selectedStatus: ClinicPurchaserCreationStatusEnum | string | undefined) {
  if (selectedStatus === '' ||
    selectedStatus === undefined) {
    return undefined;
  }
  return selectedStatus as ClinicPurchaserCreationStatusEnum;
}

const entryOptions = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
];

interface Props {
  clinic_id?: string;
}

function PurchaserList({ clinic_id }: Props) {

  const [data, setData] = useState<Array<ClinicPurchasersRetrievalClinicPurchasers>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState<ClinicPurchaserCreationStatusEnum | string | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<string | undefined>(undefined);
  const [reloadData, setReloadData] = useState<boolean>(true);
  const [pageMeta, setPageMeta] = useState<PagingMetadata>({
    page: 1,
    per_page: 10,
    total_page: 1,
    total_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const userStore = useUserStore();
  const history = useHistory();

  const setCurrentPageCallback = useCallback((selectedPage: number) => {
    setCurrentPage(selectedPage);
  }, []);

  function onSort(column: string) {
    if (sortColumn === column && sortDirection === 'asc') {
      setSortDirection('desc')
    } else {
      setSortDirection('asc')
    }
    if (sortColumn !== column) {
      setSortColumn(column);
    }
    setReloadData(!reloadData);
  }

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!adminRoleExists(userStore.user.roles)) {
        history.push("/not-found");
      }
    }

  }, [userStore.accessToken, userStore.user, history])

  useEffect(() => {
    let isSubscribed = true;

    if (!userStore.accessToken) {
      return;
    }

    if (clinic_id) {

      let clinicApi: amberSdk.ClinicApi = new amberSdk.ClinicApi(undefined, undefined, mainAxios);
      clinicApi.clinicClinicPurchasersGET(clinic_id, currentPage, rowsPerPage,
        getEncodeOrUndefined(searchTerm), getSortColumn(sortColumn), getSortDirection(sortDirection),
        getRequestConfig(userStore.accessToken))
        .then((e) => {
          if (isSubscribed) {
            setData(e.data.clinic_purchasers)
            setPageMeta(e.data.paging_metadata)
          }
        }).catch((e) => {
          if (e.response && e.response.status === 500) {
            toast.error('An error has occured. Please try again later.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (isSubscribed)
            setData([])
        }).finally(() => {
          if (isSubscribed)
            setLoading(false);
        });
    } else {

      // pull the data here and set loading to false when completed
      let purchaserApi: amberSdk.ClinicPurchaserApi = new amberSdk.ClinicPurchaserApi(undefined, undefined, mainAxios);

      purchaserApi.clinicPurchasersGET(currentPage, rowsPerPage,
        getStatusFilterArray(statusFilter),
        getEncodeOrUndefined(searchTerm), getSortColumn(sortColumn), getSortDirection(sortDirection),
        getRequestConfig(userStore.accessToken)
      ).then((e) => {
        if (isSubscribed) {
          setData(e.data.clinic_purchasers)
          setPageMeta(e.data.paging_metadata)
        }
      }).catch((e) => {
        if (e.response && e.response.status === 500) {
          toast.error('An error has occured. Please try again later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (isSubscribed)
          setData([])
      }).finally(() => {
        if (isSubscribed)
          setLoading(false);
      });
    }

    return () => {
      isSubscribed = false
    };
  }, [userStore.accessToken, currentPage, rowsPerPage, statusFilter,
    searchTerm, sortColumn, sortDirection, clinic_id])

  return (
    <div>
      <PageHeader>
        <div>
          <h1 className="page-header">Clinic Purchasers</h1>

          <p className='p1'>
            List of clinic purchasers for this clinic.
          </p>
        </div>

        {/* <Link to={clinic_id ? `/clinics/purchasers/create?clinic=${clinic_id}` : `/clinics/purchasers/create`}>
          <CreateButton>
            &#43; Create Clinic Purchaser
          </CreateButton>
        </Link> */}
      </PageHeader>
      <ListWrapper>
        <ListHeaderWrapper>
          <ListHeader>List of Clinic Purchasers</ListHeader>
        </ListHeaderWrapper>

        <ListContentWrapper>
          {/* filters */}
          {
            clinic_id ? '' :
              <FilterRow>
                <FilterColumn>
                  <h3>Account Status</h3>
                  <CustomSelect
                    onChange={(e: any) => {
                      setStatusFilter(e ? e.value : undefined);
                      setCurrentPage(1);
                    }}
                    options={userStatusOption}
                    isClearable={true}
                    placeholder="Select Status" />
                </FilterColumn>
              </FilterRow>
          }

          {/* options and search */}
          <SearchOptionRow>
            <div className="col-auto">
              <EntriesDiv>
                <span style={{ paddingRight: "8px" }} className='p1'>Show</span>
                <CustomSelect onChange={(e: any) => {
                  setRowsPerPage(e.value);
                  setCurrentPage(1);
                }} options={entryOptions} defaultValue={{ label: "10", value: 10 }} />
                <span style={{ paddingLeft: "8px" }} className='p1'>entries</span>
              </EntriesDiv>
            </div>
            <div className="col-auto">
              <SearchDiv>
                <span style={{ paddingRight: "8px" }} className='p1'>Search</span>
                <SearchInput
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    let newValue = e.currentTarget.value;
                    setSearchTerm(newValue);
                    setCurrentPage(1);
                  }}
                  placeholder="Search Clinic Purchaser Name"
                />
              </SearchDiv>
            </div>
          </SearchOptionRow>

          {/* main table */}
          <div>
            {
              loading ?
                <LoaderWrapper>
                  <BounceLoader
                    color={"#FFA300"}
                    loading={loading}
                    size={48}
                    speedMultiplier={1}
                  />
                </LoaderWrapper> :
                <PurchasersTable data={data} columns={columnsDefinition} onSort={onSort} sortOptions={{
                  sortColumn, sortDirection
                }} />
            }
          </div>

          {/* pagination */}
          <div style={{ marginTop: "16px" }}>
            <Pagination pageChangeHandler={setCurrentPageCallback} pageMeta={pageMeta} />
          </div>
        </ListContentWrapper>
      </ListWrapper>
      <PageFooter>
        <Link to={clinic_id ? `/clinics/purchasers/create?clinic=${clinic_id}` : `/clinics/purchasers/create`}>
          <CreateButton>
            &#43; Create Clinic Purchaser
          </CreateButton>
        </Link>
      </PageFooter>
    </div>
  )
};

export default PurchaserList;