import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import BounceLoader from 'react-spinners/BounceLoader';
import { Col, Container, Row } from 'reactstrap';
import { ClinicPurchaserCreation } from '../../open-api';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import { statusOptionList, countryCodeOptionList, ISelectOptionItem, adminRoleExists } from '../../common/utils';
import CustomSelect from '../../components/custom-select/custom-select';
import { getRequestConfig } from '../../common/utils';
import { useHistory } from 'react-router-dom';
import mainAxios from '../../config/axios-config';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const CreateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #005FAA;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const emptyCP: ClinicPurchaserCreation = {
  name: '',
  mobile_contact: {
    country_calling_code: '+65',
    phone_number: '',
  },
  clinic_id: '',
  status: 'ACTIVE',
}

function CreateClinicPurchaser() {
  const history = useHistory();

  const [newCP, setNewCP] = useState<ClinicPurchaserCreation>(emptyCP);
  const [loading, setLoading] = useState(true);
  const [clinicOptionList, setClinicOptionList] = useState<Array<ISelectOptionItem>>([]);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [clinicError, setClinicError] = useState(false);
  const userStore = useUserStore();

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!adminRoleExists(userStore.user.roles)) {
        history.push("/not-found");
      }
    }

  }, [userStore.accessToken, userStore.user, history])

  /**
   * get all select dropdown options
   */
  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }
    const defaultClinic = new URLSearchParams(history.location.search).get('clinic');

    let clinicApi: amberSdk.ClinicApi = new amberSdk.ClinicApi(undefined, undefined, mainAxios);
    clinicApi.clinicsGET(1, 3000, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)
    ).then((e) => {
      setClinicOptionList(
        e.data.clinics.map((e) => {
          return {
            value: e.id,
            label: e.name,
          }
        })
      );
      if (defaultClinic) {
        let tempOrder = update(emptyCP, {
          clinic_id: { $set: defaultClinic }
        })
        setNewCP(tempOrder);
      }
    }).catch((e) => {
    }).finally(() => {
      setLoading(false);
    })
  }, [userStore.accessToken, history.location.search])

  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newCP, {
      [field]: { $set: value }
    })
    setNewCP(tempOrder);

    if (field === 'name') {
      if (nameError) {
        setNameError(false);
      }
    } else if (field === 'clinic') {
      if (clinicError) {
        setClinicError(false);
      }
    }
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newCP, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewCP(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newCP.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newCP.mobile_contact.phone_number) {
      if (!mobileError) {
        setMobileError(true)
      }
      haveError = true;
    } else {
      if (mobileError) {
        setMobileError(false)
      }
    }

    if (!newCP.clinic_id) {
      if (!clinicError) {
        setClinicError(true)
      }
      haveError = true;
    } else {
      if (clinicError) {
        setClinicError(false)
      }
    }
    return haveError;
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      setLoading(false);
      return;
    }

    let cpApi: amberSdk.ClinicPurchaserApi = new amberSdk.ClinicPurchaserApi(undefined, undefined, mainAxios)

    cpApi.clinicPurchaserCreatePOST({
      name: newCP.name,
      mobile_contact: {
        country_calling_code: newCP.mobile_contact.country_calling_code,
        phone_number: newCP.mobile_contact.phone_number,
      },
      clinic_id: newCP.clinic_id,
      status: newCP.status,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully created', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewCP({
          name: '',
          mobile_contact: {
            country_calling_code: '+65',
            phone_number: '',
          },
          clinic_id: '',
          status: 'ACTIVE',
        });
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        } else if (e.response.data.error === 'invalid_clinic') {
          setClinicError(true);
        }
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1 className="page-header">Create Clinic Purchaser</h1>
      </HeaderWrapper>
      <span className='p1'>
        Create a new clinic purchaser
      </span>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "20px" }}>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Contact Name
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${nameError ? 'is-invalid' : ''}`}
              placeholder="Contact Name"
              id="contactName"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={newCP.name}
            />
            <div className="invalid-feedback">Invalid contact name</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Mobile Number*
          </Col>
          <Col md={4} style={{display: 'flex'}}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleMobileChange('country_calling_code', e.value)}
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(e => e.value === newCP.mobile_contact.country_calling_code)}
            />
            <input
              type="text"
              className={`form-control fs-13px ${mobileError ? 'is-invalid' : ''}`}
              placeholder="Mobile Number"
              id="mobileNumber"
              onChange={(e) => handleMobileChange('phone_number', e.target.value)}
              value={newCP.mobile_contact.phone_number}
            />
            <div className="invalid-feedback">Invalid mobile contact</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Clinic Name
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('clinic_id', e.value)}
              options={clinicOptionList}
              isSearchable
              placeholder='Clinic Name'
              value={clinicOptionList.find(e => e.value === newCP.clinic_id)}
              isError={clinicError}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Account Status
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('status', e.value)}
              options={statusOptionList}
              placeholder='Status'
              value={statusOptionList.find(e => e.value === newCP.status)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
          </Col>
          <Col md={2}>
            <CancelButton type='button' onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <CreateButton type='submit' onClick={handleSubmit}>
              + Create Clinic Purchaser
            </CreateButton>
          </Col>
        </Row>
      </Container>

    </div>
  )
};

export default CreateClinicPurchaser;